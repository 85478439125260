<template>
  <v-card
    outlined
    class="usecase-card mb-2"
    aria-label="Usecase card"
    @click="$emit('click', useCase)">
    <v-card-text>
      <v-row>
        <v-col
          v-if="useCase.cloud_provider"
          class="usecase-card__icon no-gutters"
          cols="1">
          <CyIconCredential
            size="32"
            :type="useCase.cloud_provider"/>
        </v-col>
        <v-col
          :class="[
            'no-gutters',
            { 'pl-0': useCase.cloud_provider } ]">
          <v-row>
            <div class="usecase-card__header">
              {{ useCase.name || '' }}
            </div>
          </v-row>
          <v-row class="mt-1">
            <div class="usecase-card__description">
              {{ useCase.description || '' }}
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CyWizardUsecaseCard',
  props: {
    useCase: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.usecase-card {
  min-height: 70px;
  cursor: pointer;

  &:hover {
    border-color: cy-get-color("secondary");
  }

  &__icon {
    display: flex;
    align-items: center;
    max-width: 64px;
  }

  &__header {
    margin: 8px 12px 0;
    color: cy-get-color("primary");
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    line-height: $line-height-default;
  }

  &__description {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-left: 12px;
  }
}
</style>
