<template>
  <div class="environment-create">
    <div
      v-if="loading"
      class="loading-spinner"
      aria-label="Loader">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>
    <v-stepper
      v-else
      v-model="step"
      flat>
      <v-stepper-header>
        <v-stepper-step
          :step="1"
          :editable="false"
          :class="{ 'v-stepper__step--passed': step > 1 }">
          {{ $t('general') }}
        </v-stepper-step>

        <v-stepper-step
          :step="2"
          class="v-stepper__step--two"
          :editable="false">
          {{ $t('configuration') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content :step="1">
          <v-row
            v-if="errors"
            class="d-block mb-4 mx-2">
            <CyAlert
              theme="error"
              :content="errors"/>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h2 class="h5">
                {{ $t('general') }}
              </h2>
            </v-col>
            <v-col
              class="ml-sm-4 mt-sm-0 mt-2"
              cols="12"
              md="5">
              <CyEnvironmentSelector
                class="mb-4"
                :autofocus="!isClonePage"
                :project-envs="envs"
                :org-envs="orgEnvs"
                :errors="envCanonicalErrors"
                :selected-env.sync="canonical"/>
              <label>{{ $t('icon') }}</label>
              <CyInputsIconPicker
                class="mt-2"
                :env-canonical="canonical"
                :color-name.sync="environment.color"
                :icon.sync="environment.icon"/>
            </v-col>
            <v-col/>
          </v-row>

          <v-divider
            v-if="hasMultipleUsecases"
            class="my-8"/>

          <v-row
            v-if="hasMultipleUsecases"
            class="mb-16"
            align="start"
            no-gutters>
            <v-col>
              <h2 class="h5">
                {{ $t('environment.useCase') }}
              </h2>
              <p>
                {{ $t('useCaseDescription') }}
              </p>
            </v-col>
            <v-col
              class="ml-4"
              cols="5">
              <ul
                v-if="_.isEmpty(environment.useCase)"
                class="usecase-list"
                aria-label="Usecase list">
                <li
                  v-for="([useCaseCanonical, useCase]) in _.toPairs(service.config)"
                  :key="useCaseCanonical">
                  <CyWizardUsecaseCard
                    :use-case="useCase"
                    @click="setUseCase(useCaseCanonical)"/>
                </li>
              </ul>
              <div v-else>
                <CyWizardUsecaseCard :use-case="envUseCase"/>
                <button
                  class="cy-link"
                  @click="resetUseCase">
                  {{ $t('changeUseCase') }}
                </button>
              </div>
            </v-col>
            <v-col/>
          </v-row>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <div v-if="step === 2">
            <CyWizardStackForms
              v-if="isFormsEnabled && hasFormsConfig"
              :key="canonical"
              v-model="rawFormsConfig"
              :forms="configForUseCase"
              :stack-ref="service.catalog.ref"
              :environment-canonical="canonical"
              :use-case="_.get(environment, 'useCase')"
              class="stackforms"
              @validate="setConfigurationValidity($event)"/>

            <CyWizardEnvConfig
              v-else
              :key="canonical"
              v-model="userConfigs"
              :service-canonical="_.get(service.catalog, 'canonical')"
              :environment="canonical"
              :project-canonical="projectCanonical"
              :config="envUseCase"
              display-expanded
              relative-height
              class="non-stackforms-editor"
              @valid="setConfigurationValidity($event)"/>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div class="environment-create__actions d-flex align-center space-x-4">
      <CyButton
        v-if="!isFirstStep"
        class="ml-auto"
        icon="chevron_left"
        theme="primary"
        variant="secondary"
        :disabled="loading"
        @click="() => stepCounter = 1">
        {{ $t('forms.back') }}
      </CyButton>
      <CyButton
        v-if="isFirstStep"
        class="ml-auto"
        :disabled="$v.$invalid"
        :loading="loading"
        theme="success"
        icon="chevron_right"
        @click="goToConfigStep">
        {{ $t('forms.next') }}
      </CyButton>
      <CyButton
        v-else
        v-has-rights-to="'UpdateProject'"
        :disabled="!isConfigurationValid"
        :loading="loading"
        theme="success"
        icon="done"
        @click="createEnvironment">
        {{ $t('environment.create') }}
      </CyButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CyEnvironmentSelector from '@/components/environment/selector.vue'
import CyInputsIconPicker from '@/components/inputs/icon-picker.vue'
import CyWizardEnvConfig from '@/components/wizard/env-config.vue'
import CyWizardStackForms from '@/components/wizard/stack-forms.vue'
import CyWizardUsecaseCard from '@/components/wizard/usecase-card.vue'
import { pagination as createAPIPage } from '@/utils/api'
import REGEX from '@/utils/config/regex'
import { constructBreadcrumb } from '@/utils/helpers'
import * as yaml from 'js-yaml'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageEnvironmentCreate',
  components: {
    CyEnvironmentSelector,
    CyInputsIconPicker,
    CyWizardUsecaseCard,
    CyWizardEnvConfig,
    CyWizardStackForms,
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    environment: {
      canonical: '',
      icon: '',
      color: '',
      useCase: '',
    },
    service: {
      catalog: null,
      config: null,
    },
    stepCounter: 1,
    loading: true,
    userConfigs: {},
    rawFormsConfig: {},
    isFormsEnabled: true,
    isConfigurationValid: false,
  }),
  breadcrumb () {
    const { projectCanonical, projectName } = this
    const title = {
      addServiceEnv: this.$t('title.addServiceEnv'),
      environmentClone: this.$t('title.environmentClone'),
    }[this.$route.name]
    return constructBreadcrumb(this.$options.name, title, [
      {
        label: this.$t('routes.projectEnvironments'),
        name: 'projectEnvironments',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    const title = {
      addServiceEnv: this.$t('title.addServiceEnv'),
      environmentClone: this.$t('title.environmentClone'),
    }[this.$route.name]
    const description = {
      text: this.$t('description'),
      link: this.$docLinks.project.environments,
    }
    return { title, description }
  },
  validations () {
    return {
      environment: {
        canonical: {
          required,
          isUnique: (env) => !this.existingEnvironments.includes(env),
          isNameValid: (env) => REGEX.ENV_NAME.test(env),
        },
        useCase: {
          required,
        },
      },
    }
  },
  computed: {
    ...mapState({
      configRepositoryErrors: (state) => state.organization.project.configRepository.errors.configRepository,
      formsErrors: (state) => state.organization.project.errors.forms,
      pipelinesErrors: (state) => state.organization.project.errors.pipelines,
      pipelineErrors: (state) => state.organization.project.pipeline.errors.pipeline,
      environmentsConfigErrors: (state) => state.organization.project.errors.environmentsConfig,
      stackErrors: (state) => state.organization.stack.errors.stack,
      stackConfigErrors: (state) => state.organization.stack.errors.stackConfig,
    }),
    ...mapState('organization', {
      orgEnvs: (state) => state.available.environments,
    }),
    ...mapGetters('organization/project', [
      'envs',
      'project',
      'configRepositoryBranch',
      'configRepositoryCanonical',
      'environmentsConfig',
    ]),
    ...mapGetters('organization/stack', [
      'stack',
      'stackConfig',
    ]),
    ...mapState('organization/quota', {
      envsResourcePool: (state) => state.envsResourcePool,
    }),
    step: {
      get () {
        return this.stepCounter
      },
      set (value) {
        if (value === 1) return
        this.stepCounter = value
      },
    },
    canonical: {
      get () {
        return this.environment.canonical
      },
      set (value) {
        this.environment.canonical = value?.toLowerCase()
      },
    },
    isFirstStep () {
      return _.isEqual(this.stepCounter, 1)
    },
    isClonePage () {
      return this.$route.name === 'environmentClone'
    },
    stackRef () {
      return this.project?.service_catalog?.ref
    },
    hasMultipleUsecases () {
      return _.size(this.service.config) > 1
    },
    existingEnvironments () {
      return _.map(this.envs, 'canonical')
    },
    errors () {
      return [
        ...this.pipelinesErrors,
        ...this.pipelineErrors,
        ...this.configRepositoryErrors,
        ...this.formsErrors,
        ...(this.hasFormsConfig ? this.environmentsConfigErrors : []),
        ...this.stackErrors,
        ...this.stackConfigErrors,
      ]
    },
    envCanonicalErrors () {
      const errors = []
      const { isUnique, isNameValid } = this.$v.environment.canonical
      if (!isUnique) errors.push(this.$t('environment.alreadyExists', { env: this.environment.canonical }))
      if (!isNameValid && !_.isEmpty(this.environment.canonical)) errors.push(this.$t('environment.nameInvalid'))
      return errors
    },
    formsConfig () {
      return _.$get(this.environmentsConfig, [this.envCanonical, 'forms'], {})
    },
    envUseCase () {
      return _.$get(this.service.config, this.environment.useCase, {})
    },
    envToCloneUseCase () {
      return _.$get(this.environmentsConfig, [this.envCanonical, 'use_case'], {})
    },
    configForUseCase () {
      const { environment, formsConfig, envToCloneUseCase } = this

      if (this.isClonePage && envToCloneUseCase === environment.useCase) {
        return _.cloneDeep(formsConfig.use_cases.find(({ name }) => name === envToCloneUseCase))
      }
      return _.get(this.envUseCase, 'forms')
    },
    hasFormsConfig () {
      return !_.isEmpty(this.envUseCase?.forms)
    },
    formInputs () {
      const { canonical, useCase } = this.environment

      return _.isEmpty(this.rawFormsConfig)
        ? {}
        : {
            use_case: useCase,
            environment_canonical: canonical,
            resource_pool_canonical: this.envsResourcePool[canonical],
            vars: this.rawFormsConfig,
          }
    },
  },
  async created () {
    await this.fetchData()
    this.updateService()
    this.isClonePage && this.cloneEnv()
    this.loading = false
  },
  beforeDestroy () {
    this.CLEAR_PROJ_ERRORS()
    this.CLEAR_CR_ERRORS()
    this.CLEAR_PIPELINE_ERRORS()
  },
  destroyed () {
    this.RESET_STACK_STATE()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
      'GET_STACK_CONFIG',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT',
      'GET_PROJECT_ENVIRONMENT_CONFIG',
      'CREATE_ENV',
      'DELETE_ENV',
    ]),
    ...mapActions('organization/project/pipeline', [
      'CREATE_PIPELINE',
      'UNPAUSE_PIPELINE',
    ]),
    ...mapActions('organization/project/configRepository', [
      'CREATE_CONFIG_REPOSITORY_FILES',
    ]),
    ...mapMutations('organization/project', [
      'SET_PROJECT',
      'CLEAR_PROJ_ERRORS',
    ]),
    ...mapMutations('organization/stack', [
      'RESET_STACK_STATE',
    ]),
    ...mapMutations('organization/project/configRepository', [
      'CLEAR_CR_ERRORS',
    ]),
    ...mapMutations('organization/project/pipeline', [
      'CLEAR_PIPELINE_ERRORS',
    ]),
    async fetchData () {
      await Promise.all([
        this.fetchEnvs(),
        this.GET_STACK_CONFIG({ stackRef: this.stackRef }),
        this.GET_STACK({ stackRef: this.stackRef }),
      ])
      if (this.isClonePage) this.getEnvFormsConfig()
    },
    updateService () {
      const { stack, stackConfig } = this
      if (stack && stackConfig) {
        this.$set(this, 'service', { catalog: stack, config: stackConfig })
        if (!this.hasMultipleUsecases) this.setUseCase(_.head(_.keys(this.service.config)))
      }
    },
    cloneEnv () {
      const { canonical, color, icon, use_case } = _.find(this.envs, { canonical: this.envCanonical })
      this.$set(this, 'environment', {
        canonical: `${canonical}-copy`,
        icon,
        color,
      })
      this.setUseCase(use_case)
    },
    fetchEnvs () {
      this.FETCH_AVAILABLE({ keyPath: 'environments', extraParams: [createAPIPage(1, 100)] })
    },
    setUseCase (useCaseCanonical) {
      this.$set(this.environment, 'useCase', useCaseCanonical)
    },
    resetUseCase () {
      this.environment.useCase = ''
    },
    setConfigurationValidity (isValid) {
      this.isConfigurationValid = isValid
    },
    getEnvFormsConfig () {
      this.GET_PROJECT_ENVIRONMENT_CONFIG(this.envCanonical)
    },
    getUpdatedEnv () {
      const { canonical, icon, color, useCase } = this.environment
      const cloudProvider = _.get(this.envUseCase, 'cloud_provider')
      return {
        canonical,
        icon,
        color,
        use_case: useCase,
        ...(cloudProvider
          ? { cloud_provider_canonical: cloudProvider }
          : {}
        ),
        ...(this.hasFormsConfig ? { inputs: [this.formInputs] } : {}),
      }
    },
    async pushConfigRepositoryFiles () {
      const { configRepositoryCanonical } = this
      if (!configRepositoryCanonical) return true

      const configObject = {
        configs: this.getUserConfigsWithDestination(this.userConfigs),
        branch: this.configRepositoryBranch,
      }

      await this.CREATE_CONFIG_REPOSITORY_FILES({ configRepositoryCanonical, configObject })
      await this.$nextTick()

      if (!_.isEmpty(this.configRepositoryErrors)) this.setRunServiceState({ state: 'error', context: 'pushConfigRepositoryFiles' })
      return _.isEmpty(this.configRepositoryErrors)
    },
    async updateProjectPipelines () {
      const pipeline = this.getPipeline()
      this.SET_PROJECT({ ...this.project, pipelines: [pipeline] })

      if (!await this.CREATE_PIPELINE(pipeline)) return false
      return await this.UNPAUSE_PIPELINE({ pipelineCanonical: pipeline.pipeline_name })
    },
    getPipeline () {
      const { canonical, icon, color, useCase } = this.environment
      const cloudProvider = _.get(this.envUseCase, 'cloud_provider')
      const pipelineName = `${this.projectCanonical}-${canonical}`
      const pipelineConfig = !this.hasFormsConfig
        ? JSON.stringify(yaml.load(this.userConfigs.pipeline.pipeline.content))
        : null
      const variables =
        !this.hasFormsConfig
          ? this.userConfigs.pipeline.variables.content
          : null

      return {
        pipeline_name: pipelineName,
        use_case: useCase,
        environment: {
          canonical,
          icon,
          color,
          ...(cloudProvider
            ? { cloud_provider_canonical: cloudProvider }
            : {}
          ),
        },
        ...(!this.hasFormsConfig ? { passed_config: pipelineConfig, yaml_vars: variables } : {}),
      }
    },
    getUserConfigsWithDestination (configs, array = []) {
      _.entries(configs).forEach(([key, value]) => {
        if (!_.isPlainObject(value)) return

        const { content, destination: path } = value
        path
          ? array.push({ content: btoa(unescape(encodeURIComponent(content))), path })
          : this.getUserConfigsWithDestination(configs[key], array)
      })

      return array
    },
    async createEnvironment () {
      let success
      this.$toggle.loading(true)
      const { projectCanonical, canonical: envCanonical } = this

      if (!this.hasFormsConfig) await this.pushConfigRepositoryFiles()

      const environment = this.getUpdatedEnv()

      success = await this.CREATE_ENV({ environment })

      if (!this.hasFormsConfig && success) {
        const deleteErroredEnv = async () => {
          await this.DELETE_ENV({ envCanonical, displaySuccessMessage: false })
          return false
        }

        success = await this.updateProjectPipelines() ? true : await deleteErroredEnv()
        await this.GET_PROJECT({ projectCanonical, envCanonical })
      }

      if (success) {
        await this.GET_PROJECT({ projectCanonical, envCanonical })

        this.$router.push({
          name: 'pipeline',
          params: {
            ...this.$route.params,
            pipelineCanonical: `${projectCanonical}-${envCanonical}`,
            envCanonical,
            projectCanonical,
          },
        })
      } else {
        this.stepCounter = 1
        this.$toggle.loading(false)
      }
    },
    async goToConfigStep () {
      this.loading = true
      const query = [
        this.environment.useCase,
        this.projectCanonical,
        this.canonical,
      ]
      await this.GET_STACK_CONFIG({ stackRef: this.stackRef, query })
      this.updateService()
      this.stepCounter = 2
      this.loading = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: {
          addServiceEnv: 'Create new environment',
          environmentClone: 'Clone environment',
        },
        description: 'Environments are instances of a stack, configured using variables you define.',
        useCaseDescription: 'A stack may include several variants to support diverse usage scenarios. Select the appropriate use case for this environment.',
        changeUseCase: 'Change use case',
      },
      es: {
        title: {
          addServiceEnv: 'Crear nuevo entorno',
          environmentClone: 'Clonando entorno',
        },
        description: 'Los entornos son instancias de una stack, configurados utilizando variables que usted define.',
        useCaseDescription: 'Un stack puede incluir varias variantes para admitir diversos escenarios de uso. Seleccione el caso de uso apropiado para este entorno.',
        changeUseCase: 'Cambiar caso de uso',
      },
      fr: {
        title: {
          addServiceEnv: 'Créer un nouvel environnement',
          environmentClone: `Cloner l'environnement`,
        },
        description: `Les environnements sont des instances d'une stack, configurés à l'aide de variables que vous définissez.`,
        useCaseDescription: `Une stack peut inclure plusieurs variantes pour prendre en charge divers scénarios d'utilisation. Sélectionnez le cas d'utilisation approprié pour cet environnement.`,
        changeUseCase: `Changer de cas d'utilisation`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.environment-create {
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &__actions {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    border-top: solid 1px cy-get-color("grey", "light-2");
    background-color: cy-get-color("white");
  }

  ::v-deep .v-stepper {
    overflow: visible;
    background: transparent;

    &__header {
      margin: 0 -32px;
      padding: 0 24px;
      overflow-y: hidden;
      border-bottom: 1px solid cy-get-color("grey", "light-2");
      background: cy-get-color("white");
      box-shadow: none;
    }

    &__step__step {
      font-weight: $font-weight-bolder;
    }

    &__items {
      display: flex;
      position: relative;
      flex: 1 1 auto;
      flex-direction: row;
      overflow: visible;
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: 32px 0 0;

      label {
        color: cy-get-color("grey", "dark-2");
      }

      .stackforms {
        height: calc(100vh - 310px);
        margin: -32px -32px 38px;
        background-color: cy-get-color("white");

        .stack-forms__group {
          position: relative;
          border-bottom: none;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -16px;
            width: calc(100% + 32px);
            height: 1px;
            background-color:cy-get-color("grey", "light-2");
          }
        }

        .side-panel {
          right: -65px;
          width: 410px;
          background-color: cy-get-color("white");
        }
      }

      .non-stackforms-editor {
        height: calc(100vh - 370px);
      }
    }

    &__wrapper {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      overflow: visible;
    }

    &__step {
      position: relative;
      flex-grow: 1;
      padding: 16px 4px 24px;

      &--active,
      &--passed {
        &::before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          width: 100%;
          height: 4px;
          border-radius: 4px;
          background-color: cy-get-color("secondary", "light-1");
        }
      }

      &--inactive {
        &::before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          width: 100%;
          height: 4px;
          border-radius: 4px;
          background-color: cy-get-color("grey");
        }
      }

      &--two {
        &::before {
          z-index: 1;
          left: -2px;
        }
      }
    }
  }

  .usecase-list {
    padding-left: 0;
    list-style-type: none;
  }
}
</style>
