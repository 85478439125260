<template>
  <v-combobox
    v-bind="$attrs"
    ref="autocomplete"
    v-model="env"
    class="required-field environments-selector"
    :label="$t('forms.fieldOrgEnv')"
    :hint="$t('hint')"
    :search-input.sync="searchInput"
    :items="filteredEnvironments"
    :error-messages="errors"
    aria-label="Environment"
    persistent-hint
    hide-selected
    required
    @input="$emit('update:selectedEnv', $event)"/>
</template>

<script>
export default {
  name: 'CyEnvironmentSelector',
  props: {
    projectEnvs: {
      type: Array,
      default: () => [],
    },
    orgEnvs: {
      type: Array,
      default: () => [],
    },
    selectedEnv: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ selectedEnv }) => ({
    searchInput: '',
    env: selectedEnv,
  }),
  computed: {
    filteredEnvironments () {
      return _.map(this.orgEnvs, 'canonical')
        .filter((canonical) => !this.existingEnvironments.includes(canonical))
    },
    existingEnvironments () {
      return _.map(this.projectEnvs, 'canonical')
    },
  },
  i18n: {
    messages: {
      en: {
        hint: 'Only hyphens, underscores and alphanumeric characters allowed.',
      },
      es: {
        hint: 'Solo están permitidos carácteres alfanuméricos, guiones y guiones bajos.',
      },
      fr: {
        hint: `Seuls les caractères alphanumériques, traits d'union et tirets bas sont permis.`,
      },
    },
  },
}
</script>
